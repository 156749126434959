.App {
  text-align: center;
  /*background-color: #282c34;*/
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
}
.App-logo {
  height: 100%;
  pointer-events: none;
}
