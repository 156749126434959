.layout-logo {
  position: relative;
  /* padding-left: 16px; */
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 20px;
  font-weight: bold;
}
.layout-logo-image {
  height: 30px;
  margin: 8px;
}
.layout-header {
  position: relative;
  height: 48px;
  line-height: 48px;
  padding: 0 8px 0 8px;
  z-index: 1;
  width: '100%';
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.layout-menu {
  height: 48px;
  color: #fff;
  /* border-bottom: 1px solid rgb(90, 104, 114); */
  padding: 8px 30px;
  font-size: 20px;
}
.layout-footer {
  padding: 8px;
  text-align: center;
}
.fill-screen {
  min-height: 100vh;
}
