.login-card {
  border: 0;
  border-radius: 8px;
  box-shadow: #0000001f 0 10px 24px;
  min-width: 350px;
  max-width: 700px;
  width: 60%;
}
.login-container {
  /*background-image: linear-gradient(-45deg, #ab83ff, #83fff0);*/
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  /*background-image: url('https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4wyTw?ver=0115');*/
  background-size: cover;
  background-position: center;
}
