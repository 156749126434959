.ant-input-number {
  width: 100% !important;
}
.ant-form-item-label {
  height: 28px;
}
@media print {
  .noprint {
    display: none !important;
    visibility: hidden !important;
    max-height: 0 !important;
    max-width: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }
  .printCard {
    border: 0 !important;
  }
  .printList {
    border: 0 !important;
  }
  .printImage {
    width: 200px !important;
  }
  .ant-card-actions {
    display: none !important;
    visibility: hidden !important;
    max-height: 0 !important;
    max-width: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }
  .printCardArticolo {
    margin-bottom: 82px !important;
  }
}
.strong-border-table .ant-table.ant-table-bordered td,
.strong-border-table .ant-table.ant-table-bordered th,
.strong-border-table .ant-table.ant-table-bordered .ant-table-container {
  border-color: #555 !important;
}
